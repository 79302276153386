import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
  const { title, lang, description } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />

        <meta
          name="image"
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/leadbeater-792b6.appspot.com/o/Mimg.png?alt=media&token=a797c99d-3584-499d-aa89-a1816a463d37"
        ></meta>
        <title>{title || 'Gatsby Simplefolio'}</title>
        <html lang={lang || 'en'} />
        <meta name="description" content={description || 'Gatsby Simplefolio'} />
      </Helmet>
      <App />
    </>
  );
};
